import { DownloadReportOptions } from './download-report-dialog.model';
import { ActionResponse, ApiFilterOptions, DropdownList, GetDataOptions } from './shared.model';
import { TemplateRef } from '@angular/core';

export enum AlertTypeEnum {
    Info,
    Warning,
    Danger,
    Success
}

export enum ActionTypeEnum {
    Menu,
    Inline
}

export enum ColumnTypeEnum {
    text,
    number,
    date,
    dropdown,
    autoComp,
    action,
    checkbox,
    phoneNumber,
    object,
    dateTime,
    address,
    actionDirect,
    amount,
    autoCompApi,
    inputUnit
}

export enum SortTypeEnum {
    asc,
    desc
}

export enum FunctionTypeEnum {
    remove,
    custom
}

export interface TableDataModel {
    id?: number | string;
    localId?: number | string
    isChecked?: boolean
}

export interface TableActionList<T> {
    functionType?: FunctionTypeEnum
    actionFunc?: ((item: T) => Promise<ActionResponse<T>>) | ((item: T) => ActionResponse<T>)
    label: string
    icon?: string
    class?: string
    isFuncAsync?: boolean
    isDanger?: boolean
    hideAction?: (item: T) => boolean
    actionDisabled?: boolean
    actionFuncHeader?: (() => Promise<ActionResponse<T>>) | (() => ActionResponse<T>)
}

export class ColumnSortOptions {
    key: string = ""
    sortType: string = ""
}

/***** ******/

export interface ColumnTable<T> {
    label: string
    key: string
    type?: ColumnTypeEnum
    width?: string
    allowStatus?: boolean
    statusTemplate?: TemplateRef<any> | null
    hideSorting?: boolean
    sortType?: string
    statusListFunction?: (item: T) => string | {}
    isChecked?: boolean
    visible: boolean
    dialogAction?: ((item: T) => Promise<ActionResponse<T>>) | ((item: T) => ActionResponse<T>) | null
    isFuncAsync?: boolean
    optionsList?: DropdownList[]
    hide?: boolean,
    positionAtEnd?: boolean
    unit?: string
    isContainsComma?: boolean
    isUnitAfterContent?: boolean
    columnLink?: ((item?: any) => string) | ColumnLink | null
}

export interface ColumnLink {
    columnLink?: ((item?: any) => string)
    isOpenNewTab : boolean
}

export interface TableOptions<T> {
    name: TablesNameEnum
    allowSearch?: boolean
    allowPaging?: boolean
    allowLengthChange?: boolean
    allowOrdering?: boolean
    allowInfo?: boolean
    pageLength?: number
    tableCells?: boolean
    addNewRecord?: boolean
    column: ColumnTable<T>[],
    allowActions: boolean,
    actionType?: ActionTypeEnum
    actionsList?: TableActionList<T>[]
    getData: (options: GetDataOptions, apiFilterOptions: ApiFilterOptions) => Promise<ActionResponse<T>>
    removeRow?: (item: T) => Promise<ActionResponse<T>>
    addRow?: (() => Promise<ActionResponse<T>>) | null
    addRowLabel?: string
    noRecordTemplate?: TemplateRef<any> | null
    tableHeaderAction?: TemplateRef<any> | null
    filterTemplate?: TemplateRef<any> | null
    allowSettings?: boolean
    allowTableHeader?: boolean
    allowCards?: boolean
    cardTemplate?: TemplateRef<any> | null
    cardClass?: string
    allowCardAction?: boolean
    showInActive?: boolean
    tableHeight?: string
    staticData?: boolean
    fromMainTabs?: boolean
    fromSubTabs?: boolean
    tableHeaderActions?: TableActionList<T>[]
    showArchivedFilter?: boolean
    importListFunc?: (() => Promise<ActionResponse<T>>) | null
    exportList?: ExportList | null
    report?: TableReport | null
    showAs?: string
    isActive?: boolean
    isShowActionOnlyInFirstRow?: boolean
    getAllData?: boolean
}

interface TableReport {
    mainButtonLabel: string
    reportListPDF: ReportListPDF | null
    reportDetailsPDF: ReportDetailsPDF | null
}

interface ExportList {
    exportColumnApi: string
    exportApi: string
    fileName: string
}

interface ReportListPDF{
    label: string
    reportAPI: string
}

interface ReportDetailsPDF {
    label: string
    reportAPI: string
    downloadReportOptions: DownloadReportOptions
}

export enum TablesNameEnum {
    Drivers = "Drivers",
    Trucks = "Trucks",
    AddTruck = "AddTruck",
    ClaimOtherDocuments = "ClaimOtherDocuments",
    Claims = "Claims",
    AccountRequests = "AccountRequests",
    AddTrailer = "AddTrailer",
    Trailers = "Trailers",
    TrailerOtherDocuments = "TrailerOtherDocuments",
    AddDriver = "AddDriver",
    SystemBlogs = "SystemBlogs",
    Subscriptions = "Subscriptions",
    Employees = "Employees",
    EndorsementAgentInsurancePolicy = "EndorsementAgentInsurancePolicy",
    RoleUsers = "RoleUsers",
    Inspections = "Inspections",
    Providers = "Providers",
    ProviderContacts = "ProviderContacts",
    ProviderAddress = "ProviderAddress",
    CurrentPairings = "CurrentPairings",
    PairingRequests = "PairingRequests",
    InsuranceTruckList = "InsuranceTruckList",
    InsuranceTrailerList = "InsuranceTrailerList",
    InsuranceDriverList = "InsuranceDriverList",
    InspectionViolations = "InspectionViolations",
    Roles = "Roles",
    InsuranceRenewalTrucks = "InsuranceRenewalTrucks",
    InsuranceRenewalTrucksTerminated = "InsuranceRenewalTrucksTerminated",
    RoleMembers = "RoleMembers",
    RolesSystem = "RolesSystem",
    RoleSystemMembers = "RoleSystemMembers",
    PricingHistory = "PricingHistory",
    IFTATruck = "IFTATruck",
    Endorsements = "Endorsements",
    JobOpenings = "JobOpenings",
    JobOpeningHiring = "JobOpeningHiring",
    JobOpeningApplicants = "JobOpeningApplicants",
    Insurances = "Insurances",
    InsuranceEndorsements = "InsuranceEndorsements",
    InsuranceClaims = "InsuranceClaims",
    InsuranceRenewalTrailers = "InsuranceRenewalTrailers",
    InsuranceRenewalTrailersTerminated = "InsuranceRenewalTrailersTerminated",
    InsuranceRenewalIFTA = "InsuranceRenewalIFTA",
    InsuranceRenewalLossRuns = "InsuranceRenewalLossRuns",
    InsuranceRenewals = "InsuranceRenewals",
    InsuranceRenewalDrivers = "InsuranceRenewalDrivers",
    InsuranceRenewalDriversTerminated = "InsuranceRenewalDriversTerminated",
    IncidentOtherDocuments = "IncidentOtherDocuments",
    Incidents = "Incidents",
    Filings = "Filings",
    IFTA = "IFTA",
    FilingNyHut = "FilingNyHut",
    FilingIFTA = "FilingIFTA",
    FilingBasicInformation = "FilingBasicInformation",
    FeatrueToggle = "FeatrueToggle",
    EndorsementChangeRequests = "EndorsementChangeRequests",
    EndorsementPolicy = "EndorsementPolicy",
    EndorsementInsurancePolicy = "EndorsementInsurancePolicy",
    PrintEndorsementAgent = "PrintEndorsementAgent",
    ReturnToDutyTest = "ReturnToDutyTest",
    RandomTest = "RandomTest",
    DrugAlcoholTest = "DrugAlcoholTest",
    DriverOtherDocuments = "DriverOtherDocuments",
    DriverViolations = "DriverViolations",
    DriverResidence = "DriverResidence",
    DriverIncidents = "DriverIncidents",
    DriverExperience = "DriverExperience",
    DriverEmployment = "DriverEmployment",
    DriverCdl = "DriverCdl",
    DriverAnnualReview = "DriverAnnualReview",
    CompanyDocuments = "CompanyDocuments",
    CompanyContacts = "CompanyContacts",
    CompanyAddress = "CompanyAddress",
    ClaimDriver = "ClaimDriver",
    ClaimVehicle = "ClaimVehicle",
    ClaimOtherExpense = "ClaimOtherExpense",
    TruckOtherDocuments = "TruckOtherDocuments",
    PairingHistory = "PairingHistory",
    Repairs = "Repairs",
    ReplacedParts = "ReplacedParts",
    Miscellaneous = "Miscellaneous",
    Labor = "Labor",
    MaintenanceSchedules = "MaintenanceSchedules",
    EnrolledUnits = "EnrolledUnits",
    RepairHistory = "RepairHistory",
    TruckRepairHistory = "TruckRepairHistory",
    Invoices = "Invoices",
    SalesInvoices = "SalesInvoices",
    HosViolationDriver = "HosViolationDriver",
    HosUnverifiedLogsSummary = "HosUnverifiedLogsSummary",
    HosUnidentifiedLogsSummary = "HosUnidentifiedLogsSummary"
}