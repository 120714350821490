import { environment } from './../../../../environments/environment';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DateService } from '../../services/date.service';
import { DateTypeEnum } from '../../model/shared.model';


@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DatePickerComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => DatePickerComponent),
        },
    ],
})
export class DatePickerComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy, Validator, OnChanges {

    constructor(
        private dateService: DateService,
        private elementRef: ElementRef
    ) {
    }

    @ViewChild('dateContainer') dateContainer: ElementRef | null = null;
    @ViewChild('list') list: any | null = null;
    @Input() maxDate?: any;
    @Input() minDate?: Date;
    @Input() viewMode: boolean = false;
    @Input() fromModal: boolean = false;
    @Input() readonly: boolean = false;
    @Input() dateType: DateTypeEnum = DateTypeEnum.date;
    @Input() openlistInTop: boolean = false;
    @Input() inline: boolean = false;
    @Input() id: string = String(Math.floor(Math.random() * 900000) + 100000);
    @Output() changeValueEvent = new EventEmitter<Date | string | null>();
    @Input()  hasExpirationDate: boolean = false;
    // showInline: boolean = false;
    date: Date | null = null;
    containerTopInit: number | null = null;
    onTouched = () => { };
    onChange = (date: any) => { };
    touched = false;
    disabled = false;
    environment = environment;

    public get dateTypeEnum(): typeof DateTypeEnum {
        return DateTypeEnum;
    }
    

    // @HostListener('document:click', ['$event'])
    // onClick(event: Event) {
    //     // if (this.dateContainer?.nativeElement.id == this.id) {
    //         const clickedElement = event.target as HTMLElement;
    //         // console.log(clickedElement.classList)
    //         const isClickedInside = clickedElement.classList.contains('p-element') ||
    //             clickedElement.classList.contains('p-datepicker-header') ||
    //             clickedElement.classList.contains('p-datepicker-next-icon') ||
    //             clickedElement.classList.contains('p-datepicker-prev-icon')
    //             // clickedElement.classList.contains('custom-data-input')
    //         const clickedInside = this.dateContainer?.nativeElement.contains(DatePickerComponent);
    //         console.log(clickedInside)
    //         // if (isClickedInside) {
    //         //     this.showInline = true;
    //         // } else if (!clickedInside) {
    //         //     this.showInline = false;
    //         // }
    //     // }
    // }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        
    }

    private clickListener: any;
    ngAfterViewInit() {
        this.clickListener = this.onClickOutside.bind(this);
        document.addEventListener('click', this.clickListener);
        // let elements = this.elementRef.nativeElement.querySelectorAll('.p-inputtext')
        // if (elements.length > 0) {
        //     elements[0].id = this.id.replace('date-input-', '');
        // }
    }

    validate(c: AbstractControl): ValidationErrors | null {
        if (c.value) {
            // afraid of this line
            if (this.minDate) {
                let selectedDateTime: Date | null = null;
                const minDate = new Date(this.minDate);
                if (this.dateType == DateTypeEnum.date) {
                    minDate.setHours(0, 0, 0, 0);
                    selectedDateTime = new Date(c.value);
                    selectedDateTime.setHours(0, 0, 0, 0);
                } else if (this.dateType == DateTypeEnum.dateTime || this.dateType == this.dateTypeEnum.time) {
                    selectedDateTime = this.dateService.toDate(c.value);
                }
                // selectedDateTime.setHours(selectedDateTime.getHours(), selectedDateTime.getMinutes(), 0, 0);
                if (selectedDateTime && selectedDateTime < minDate) {
                    return {
                        minDate: {},
                    };
                }
            } else {
                return null;
            }
        }
        return null;
    }

    /* I have incorporated a date picker into my code by setting the inline property to true.
    This allows the date picker to be displayed within a container, enabling the container element to show a scrollbar if the content exceeds the parent window's size.
    However, I encountered an issue where the date picker would unexpectedly close whenever I clicked anywhere, including inside the date picker itself.
    To resolve this problem, I implemented a solution in my code to intercept the click event and prevent the date picker from closing when clicked inside it. */
    handleClick(event: MouseEvent, id: number | string) {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside) {
            // event.stopPropagation();
        }
    }

    onClickOutside(event: MouseEvent) {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside) {
            // this.showInline = false;
        }
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.clickListener);
    }

    private relocateList() {
        let width =
            this.dateContainer?.nativeElement.getBoundingClientRect().width;
        let top =
            this.dateContainer?.nativeElement.getBoundingClientRect().top;
        let left =
            this.dateContainer?.nativeElement.getBoundingClientRect().left;
        if (this.list) {
            this.list.el.nativeElement.children[0].children[2].style.position = 'fixed'
            this.list.el.nativeElement.children[0].children[2].style.top! = top + 32 + 'px';
            this.list.el.nativeElement.children[0].children[2].style.left! = left + 'px';
        }

        this.containerTopInit = top;
    }

    writeValue(val: Date | string | null = null): void {
        if (val) {
            if (val instanceof Date) {
                this.date = val;
            } else if (typeof val === 'string' && val.length > 0) {
                if (this.dateType == DateTypeEnum.date) {
                    let convertedDate = this.dateService.fakeISOString(val, true, true);
                    this.date = convertedDate ? new Date(convertedDate) : null;
                } else if (this.dateType == DateTypeEnum.dateTime || this.dateType == DateTypeEnum.time) {
                    this.date = this.dateService.toDate(val);
                }
            } else
                this.date = null;
        } else {
            this.date = null;
        }
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    clickDate() {
        if (this.fromModal) {
            // this.relocateList();
        }
        else {
            // const element: any = document.getElementsByClassName("p-datepicker-group-container");
            // if (element) {
            //     element[0].scrollIntoView({ block: 'center' })
            // }
        }

    }

    onClose() {
        this.markAsTouched();
        if (this.date == null) {
            this.onChange(null);
            this.changeValueEvent.emit(null);
        }
    }

    onSelect(fromDateTime: boolean = false) {
        this.markAsTouched();
        if (this.date) {
            if (this.dateType == DateTypeEnum.date) {
                let val = this.dateService.fakeISOString(this.date, false, true);
                this.onChange(val);
                this.changeValueEvent.emit(val);
            } else if (this.dateType == DateTypeEnum.dateTime || this.dateType == DateTypeEnum.time) {
                let isoDate = this.dateService.toISOString(this.date)
                this.onChange(isoDate);
                this.changeValueEvent.emit(isoDate);
            }
        } else {
            this.onChange(null);
            this.changeValueEvent.emit(null);
        }
        // if (!fromDateTime) {
        //     this.showInline = false;
        // }
    }

    getHTMLDate() {
        if (this.date)
            return new Date(this.date).toISOString().split("T")[0];
        else
            return ""
    }

    // changeDateVal(val: any) {
    //     let date: any = new Date(val)
    //     if (date == "Invalid Date") {
    //         this.date = null;
    //     } else {
    //         this.date = new Date(val);
    //     }
    //     this.onChange(this.date);
    //     this.changeValueEvent.emit(this.date);
    // }

    onChangeInput() {
        this.markAsTouched();
        if (this.date) {
            if (this.dateType == DateTypeEnum.date) {
                let val = this.dateService.fakeISOString(this.date, false, true);
                this.onChange(val);
                this.changeValueEvent.emit(val);
            } else if (this.dateType == DateTypeEnum.dateTime || this.dateType == DateTypeEnum.time) {
                let isoDate = this.dateService.toISOString(this.date)
                this.onChange(isoDate);
                this.changeValueEvent.emit(isoDate);
            }
        } else {
            this.onChange(null);
            this.changeValueEvent.emit(null);
        }
    }
    //** use when date is inline */
    // getDateTimeLocalVal() {
    //     let date: any = this.date;
    //     if (date == "Invalid Date") {
    //         // this.date = null;
    //         return null;
    //     }
    //     if (date) {
    //         return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    //             .toISOString()
    //             .slice(0, -8);
    //     } else {
    //         // this.date = null;
    //         return null;
    //     }
    // }

    isDateExpired(){

        if (this.date) {
            let date: any = new Date(this.date);
            if (date < new Date()) {
                return true;
            }
        }
        return false;
    }
}
