<div class="form-group" [id]="id">
    <label>{{ label | translate }}
        <span *ngIf="!viewMode && (isRequired || isInfoRequired)"
        [class]="isInfoRequired ? 'required-black' : 'required'">*</span>
    </label>
    
    <span class="tooltip-custom" *ngIf="tooltip">
        <img src="/assets/images/info-icon-empty.svg" alt="" placement="end" ngbPopover="{{tooltip |translate}}" />
    </span>
    <ng-container *ngIf="viewMode && fileId == null;else editMode">
        <div class="file-upload no-file-upload" [class.view-mode]="viewMode">
            <label class="drag-zone drag-zone-disabled file-upload-highlight">
                <div class="upload-info">
                    <div class="icon">
                        <i class="icon-file-search-02"></i>
                    </div>
                    <div class="upload-label">
                        <span class="body-long-1"> {{"NO_FILE_UPLOADED" |translate}}</span>
                    </div>
                </div>
            </label>
        </div>
    </ng-container>
    <ng-template #editMode>
        <ng-container *ngIf="fileId;else fileNotUpload">
            <div class="file-upload" [class.view-mode]="viewMode">
                <label class="drag-zone drag-zone-disabled file-upload-highlight">
                    <div class="upload-info">
                        <div class="icon">
                            <img src="assets/images/cloud-check.svg" [ngStyle]="{'margin-top': fileId ? '4px' : '6px'}">
                        </div>
                        <ng-container *ngIf="!isBusy;else progress">
                            <div class="upload-label" (click)="downloadFile(true)" style="cursor: pointer;">
                                <button class="actions-button" style="margin-left: -0.65rem;">
                                    <span class="body-long-1"> {{"CLICK_TO_VIEW" |translate}}</span>
                                </button>
                            </div>
                            <div class="actions">
                                <button class="actions-button" *ngIf="!hideDownload" (click)="downloadFile()">

                                    <i class="icon-download-01 icon-s-m" style="color: #6F6F6F;"></i>
                                </button>
                                <button class="actions-button" *ngIf="!hideDelete && !readonly && !viewMode"
                                    (click)="deleteFile()">
                                    <i class="icon-trash-03 icon-s-m" style="color: #6F6F6F;"></i>
                                </button>
                                <button *ngIf="signatureId" class="actions-button" (click)="onSignaturedetails()">
                                    <img src="/assets/images/signature-gray.svg">
                                </button>
                            </div>
                        </ng-container>
                        <ng-template #progress>
                            <div class="progress-container">
                                <div class="progress">
                                    <div class="progress-bar" [ngStyle]="{'width': counter +'%'}" role="progressbar"
                                        aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="body-short-1 percentage">
                                    {{counter}}%
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </label>
            </div>
        </ng-container>
        <ng-template #fileNotUpload>
            <div class="file-upload">
                <label class="drag-zone file-upload-highlight" [appDragDrop]="true" (dropped)="handleFileDrop($event)">
                    <div class="upload-info">
                        <div class="icon">
                            <i class="icon-upload-cloud-02"></i>
                        </div>
                        <ng-container *ngIf="!isBusy;else progress">
                            <div class="upload-label">
                                <span class="label-1 bold">{{"CLICK_TO_UPLOAD" |translate}}</span>
                                <span class="label-1"> {{"OR_DRAG_AND_DROP" |translate}}</span>
                            </div>
                        </ng-container>
                        <ng-template #progress>
                            <div class="progress-container">
                                <div class="progress">
                                    <div class="progress-bar" [ngStyle]="{'width': counter +'%'}" role="progressbar"
                                        aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="body-short-1 percentage">
                                    {{counter}}%
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <input type="file" [multiple]="false" (change)="uploadFile($event)" #fileUploadInput
                        [id]="'app-upload-' + id" class="visually-hidden" />
                    <ng-content></ng-content>
                </label>
            </div>
        </ng-template>

    </ng-template>
</div>