<div class="tc-table" [class.tc-card]="showAs == 'card'">
    <div class="table-header" *ngIf="dataTableConfig?.allowTableHeader">
        <div class="actions-section">
            <div class="table-head-buttons">
                <div class="settings" *ngIf="
                        dataTableConfig?.allowSettings ||
                        dataTableConfig?.allowSettings == undefined
                    ">
                    <button class="secondary-button-large" id="tc-settings" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <img src="/assets/images/table-settings-icon.svg" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right settings-dropdown" aria-labelledby="tc-settings">
                        <div class="title heading-6" onclick="event.stopPropagation()">
                            {{ "TABLE_SETTINGS" | translate }}
                        </div>
                        <div class="show-as" *ngIf="dataTableConfig?.allowCards" onclick="event.stopPropagation()">
                            <span class="label-1">{{
                                "SHOW_AS" | translate
                                }}</span>
                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="showAs" id="show-as-list"
                                        value="list" [(ngModel)]="showAs" (ngModelChange)="showAsChange()" />
                                    <label class="form-check-label" for="show-as-list">
                                        {{ "LIST" | translate }}
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="showAs" id="show-as-card"
                                        value="card" [(ngModel)]="showAs" (ngModelChange)="showAsChange()" />
                                    <label class="form-check-label" for="show-as-card">
                                        {{ "CARD" | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="filter-check-boxs"
                            *ngIf="dataTableConfig?.showInActive || dataTableConfig?.showArchivedFilter"
                            onclick="event.stopPropagation()">
                            <div class="show-inactive" *ngIf="dataTableConfig?.showInActive">
                                <div class="form-group">
                                    <tc-checkbox [ngModel]="!isActive" [label]="'SHOW_INACTIVE'" 
                                        [id]="'show-inactive'" (changeValue)="showInactiveFun()"></tc-checkbox>
                                </div>
                            </div>
                            <div class="show-archived" *ngIf="dataTableConfig?.showArchivedFilter">
                                <div class="form-group">
                                    <tc-checkbox  [ngModel]="!isActive" [label]="'SHOW_ARCHIVED'" 
                                        [id]="'show-archived-filter'" (changeValue)="showArchivedFun()"></tc-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="show-columns dropdown" *ngIf="showAs == 'list'" onclick="event.stopPropagation()">
                            <div class="btn-group dropstart">
                                <button type="button" class="secondary-button-large" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <img src="assets/images/arrow-right-black.svg" />
                                    <span>{{ "COLUMNS" | translate }}</span>
                                </button>
                                <ul class="dropdown-menu">
                                    <ng-container *ngFor="
                                            let column of dataTableConfig?.column;
                                            let i = index
                                        ">
                                        <li *ngIf="
                                                column.type !=
                                                    columnTypeEnum.action &&
                                                i != 0 &&
                                                !column.hide
                                            ">
                                            <a class="dropdown-item">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="visible-column-{{
                                                            i
                                                        }}" [(ngModel)]="
                                                            column.visible
                                                        " />
                                                    <label class="form-check-label body-short-1" for="visible-column-{{
                                                            i
                                                        }}">{{
                                                        column.label
                                                        | translate
                                                        }}</label>
                                                </div>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                        <div class="row table-settings-action"
                            *ngIf="featureService.checkAccess('tc-table-settings-action')">
                            <div class="col-6">
                                <button class="primary-button-large" (click)="saveSettings()">{{"SAVE"
                                    |translate}}</button>
                            </div>
                            <div class="col-6">
                                <button class="secondary-button-large w-100" (click)="resetSettings()">{{"RESET"
                                    |translate}}</button>
                            </div>
                        </div>
                    </ul>
                </div>
                <div class="" *ngIf="featureService.checkAccess('tc-table-refresh-button')">
                    <button class="secondary-button-large" id="tc-refresh-table" (click)="refresh()" placement="top"
                        ngbPopover="{{'REFRESH_DATA' |translate}}" style="padding-top: 0.4rem;min-width: 3.6rem;">
                        <i class="icon-refresh-cw-02 icon-s"></i>
                    </button>
                </div>
                <div class="header-actions"
                    *ngIf="dataTableConfig && dataTableConfig.tableHeaderActions && dataTableConfig.tableHeaderActions.length > 1">
                    <div class="table-actions">
                        <div class="table-actions-list">
                            <button class="secondary-button-large" [matMenuTriggerFor]="menu" id="tc-header-actions">
                                <img src="/assets/images/dots.svg" />
                            </button>
                            <mat-menu #menu="matMenu" xPosition="before">
                                <button mat-menu-item class="body-short-2"
                                    [class.mat-action-disabled]="action.actionDisabled"
                                    *ngFor="let action of dataTableConfig?.tableHeaderActions" (click)="
                                    callTableHeaderAction(action)">
                                    <!-- <img class="drop-down-item-icon" src="/assets/images/{{action.icon}}" /> -->
                                    <i class="{{action.icon}} drop-down-item-icon"></i>
                                    <span class="action-label">{{action.label| translate}} </span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showActionButtonsResponsive &&
                dataTableConfig?.addRow &&
                !dataTableConfig?.importListFunc && 
                !dataTableConfig?.exportList && 
                !(dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportListPDF) &&
                !(dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportDetailsPDF); else showResponsivebutton
                ">
                    <div class="table-buttons">
                        <button class="tc-primary-add-button" id="add-new-row" (click)="addRow()"
                            *ngIf="dataTableConfig?.addRow">
                            <span>{{
                                dataTableConfig?.addRowLabel ?? "Add New"
                                | translate
                                }}</span>
                        </button>
                    </div>
                </ng-container>
                <ng-template #showResponsivebutton>
                    <div class="table-buttons-responsive settings" *ngIf="showActionButtonsResponsive && (
                        dataTableConfig?.addRow || 
                        dataTableConfig?.importListFunc ||
                        dataTableConfig?.exportList || 
                        (dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportListPDF) ||
                        (dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportDetailsPDF)
                    )">
                        <button class="secondary-button-large" id="table-buttons-responsive" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="icon-dots-vertical icon-s-m" style="position: relative;right: 2px;top: 2px;"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right settings-dropdown"
                            aria-labelledby="table-buttons-responsive" onclick="event.stopPropagation()">
                            <li (click)="addRow()" *ngIf="dataTableConfig?.addRow">
                                <i class="icon-plus"></i>
                                <span class="body-short-1">
                                    {{dataTableConfig?.addRowLabel ?? "Add New"| translate}}
                                </span>
                            </li>
                            <li *ngIf="dataTableConfig?.importListFunc" (click)="importList()">
                                <i class="icon-download-01"></i>
                                <span class="body-short-1">
                                    {{"IMPORT_LIST" | translate}}
                                </span>
                            </li>
                            <li *ngIf="dataTableConfig?.exportList" (click)="exportList()">
                                <i class="icon-upload-01"></i>
                                <span class="body-short-1">
                                    {{"EXPORT_LIST" | translate}}
                                </span>
                            </li>
                            <li (click)="reportListPDF()" *ngIf="dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportListPDF">
                                <i class="icon-list"></i>
                                <div class="body-short-1">
                                    {{ dataTableConfig.report.reportListPDF.label | translate}} Report
                                </div>
                            </li>
                            <li (click)="reportDetailsPDF()"
                                *ngIf="dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportDetailsPDF">
                                <i class="icon-file-check-02"></i>
                                <div class="body-short-1">
                                    {{ dataTableConfig.report.reportDetailsPDF.label | translate}} Report
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-template>
                <div class="table-buttons" *ngIf="!showActionButtonsResponsive">
                    <section class="table-buttons-responsive settings" style="width: 0;">
                        <ul class="dropdown-menu dropdown-menu-right settings-dropdown" aria-labelledby="report-btn"
                            onclick="event.stopPropagation()">
                            <li (click)="reportListPDF()" *ngIf="dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportListPDF">
                                <i class="icon-list"></i>
                                <div class="body-short-1">
                                    {{ dataTableConfig.report.reportListPDF.label | translate}}
                                </div>
                            </li>
                            <li (click)="reportDetailsPDF()"
                                *ngIf="dataTableConfig && dataTableConfig.report && dataTableConfig.report.reportDetailsPDF">
                                <i class="icon-file-check-02"></i>
                                <div class="body-short-1">
                                    {{ dataTableConfig.report.reportDetailsPDF.label | translate}}
                                </div>
                            </li>
                        </ul>
                    </section>
                    <button class="secondary-button-large report-btn" id="report-btn" data-bs-toggle="dropdown"
                        *ngIf="dataTableConfig && dataTableConfig.report && (dataTableConfig.report.reportListPDF || dataTableConfig.report.reportDetailsPDF) && featureService.checkAccess('tc-print-report-advanced')"
                        aria-expanded="false">
                        <span>
                            {{dataTableConfig.report.mainButtonLabel |translate}}
                        </span>
                    </button>
                    <button class="secondary-button-large export-btn" *ngIf="dataTableConfig?.exportList"
                        (click)="exportList()">
                        <span>
                            {{ "EXPORT_LIST" | translate }}
                        </span>
                    </button>
                    <button class="secondary-button-large import-btn" *ngIf="dataTableConfig?.importListFunc"
                        (click)="importList()">
                        <span>
                            {{ "IMPORT_LIST" | translate }}
                        </span>
                    </button>
                    <button class="tc-primary-add-button" id="add-new-row" (click)="addRow()"
                        *ngIf="dataTableConfig?.addRow">
                        <span>{{
                            dataTableConfig?.addRowLabel ?? "Add New"
                            | translate
                            }}</span>
                    </button>
                </div>
            </div>
            <div class="other-actions">
                <div class="filters">
                    <!-- <div class="row align-items-baseline"> -->
                    <div class="search">
                        <div class="form-group" *ngIf="dataTableConfig?.allowSearch">
                            <input class="body-short-2" placeholder="{{ 'SEAECH_BY_KEYWORD' | translate }}" type="text"
                                [(ngModel)]="searchInput" (ngModelChange)="searchApi()" />
                        </div>
                        <div class="number-of-selected" *ngIf="selectedItems.length > 0">
                            ({{selectedItems.length}} {{selectedItems.length == 1 ? "row" : "rows"}} selected. <a
                                class="deselect-all" (click)="deselectAll()">Deselect All</a>)
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
                <div class="action-template" *ngIf="dataTableConfig && dataTableConfig.tableHeaderAction">
                    <ng-container *ngTemplateOutlet="dataTableConfig.tableHeaderAction">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- [class.data-table-in-main-tabs]="dataTableConfig?.fromMainTabs" -->
    <!-- [class.data-table-in-sub-tabs]="dataTableConfig?.fromSubTabs" -->
    <div id="data-table" class="data-table gridtable" [ngStyle]="{
            height: dataTableConfig?.tableHeight
                ? dataTableConfig?.tableHeight
                : '',
            'min-height': dataTableConfig?.tableHeight
                ? dataTableConfig?.tableHeight
                : ''
        }" *ngIf="showAs == 'list'" [ngClass]="isBusy ? 'wrapper-disabled' : ''" (scroll)="onScroll($event)">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <ng-container *ngFor="let item of dataTableConfig?.column">
                        <th resizable *ngIf="item.visible == true && !item.hide" [class.allow-sorting]="
                                dataTableConfig?.allowOrdering &&
                                !item.hideSorting
                            " [ngStyle]="{
                                width: item.width ? item.width : 'auto'
                            }" [class.action-cell]="
                                item.type == columnTypeEnum.action
                            ">
                            <ng-container [ngSwitch]="item.type">
                                <ng-container *ngSwitchCase="columnTypeEnum.checkbox">
                                    <div class="form-check">
                                        <!-- <input *ngIf="tableData.length > 0" class="form-check-input" type="checkbox"
                                            id="flexCheckDefault" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Select All" (change)="selectAll()" [(ngModel)]="selectedAll"> -->
                                        <mat-checkbox class="example-margin" [checked]="allComplete" color="primary"
                                            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                        </mat-checkbox>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div class="title" [ngClass]="{
                                            'column-action':
                                                item.type ==
                                                columnTypeEnum.action
                                        }">
                                        <span>
                                            {{ item.label | translate }}
                                        </span>
                                        <span (click)="sort(item)" class="sort" [ngClass]="{
                                                'sort-asc':
                                                    item.sortType == 'asc',
                                                'sort-desc':
                                                    item.sortType == 'desc'
                                            }">
                                        </span>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody class="tbody">
                <ng-container [class.d-none]="
                        !isBusy &&
                        (tableData == undefined || tableData.length == 0)
                    ">
                    <tr *ngFor="let item of hackTableData; let i = index">
                        <!-- checkbox select -->
                        <ng-container *ngFor="let column of dataTableConfig?.column">
                            <td *ngIf="column.visible == true && !column.hide" [ngStyle]="{
                                    width: column.width ? column.width : 'auto'
                                }" [class.action-cell]="
                                    column.type == columnTypeEnum.action
                                ">
                                <!-- target="{{getIsOpenNewTab(column) ? '_blank': '_self'}}" -->
                                <!-- [routerLink]="getColumnLink(column,item)" -->
                                <!-- [attr.href]="column.columnLink ? 'javascript.void(0)': null" -->
                                <a style="display: block;height: 100%;cursor: default;"
                                    [class.column-action]="column.columnLink || column.dialogAction"
                                    (click)="callActionColumn(column, item)">
                                    <ng-container [ngSwitch]="column.type">
                                        <!-- checkbox select -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.checkbox">
                                            <div class="form-check">
                                                <!-- <input class="form-check-input" type="checkbox" id="select-item-{{i+1}}"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Select"
                                                    (change)="selectItem($event,item)" [checked]="item.isChecked"> -->
                                                <mat-checkbox [(ngModel)]="item.isChecked" (ngModelChange)="
                                                        updateAllComplete(item)
                                                    " color="primary">
                                                </mat-checkbox>
                                            </div>
                                        </ng-container>
                                        <!-- Date Label  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.date">
                                            <ng-container *ngIf="
                                                    column.allowStatus &&
                                                        column.statusTemplate;
                                                    else noStatus
                                                ">
                                                <ng-container *ngTemplateOutlet="
                                                        column.statusTemplate;
                                                        context: {
                                                            item: item,
                                                            column: column
                                                        }
                                                    ">
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noStatus>
                                                <span [class.justify-content-end]="
                                                        column.positionAtEnd
                                                    " *ngIf="
                                                        item[column.key];
                                                        else noData
                                                    ">{{
                                                    dateService.fakeISOString(item[column.key], true) | date :
                                                    environment.dateFormat
                                                    }}</span>
                                                <ng-template #noData>
                                                    <span></span>
                                                </ng-template>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Date Time Label  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.dateTime">
                                            <ng-container *ngIf="
                                                    column.allowStatus &&
                                                        column.statusTemplate;
                                                    else noStatus
                                                ">
                                                <ng-container *ngTemplateOutlet="
                                                        column.statusTemplate;
                                                        context: {
                                                            item: item,
                                                            column: column
                                                        }
                                                    ">
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noStatus>
                                                <span [class.justify-content-end]="
                                                        column.positionAtEnd
                                                    " *ngIf="
                                                        item[column.key];
                                                        else noData
                                                    ">{{
                                                    dateService.toDate(item[column.key]) | date :
                                                    environment.dateTimeFormat
                                                    }}</span>
                                                <ng-template #noData>
                                                    <span></span>
                                                </ng-template>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Phone Number  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.phoneNumber">
                                            <span [class.justify-content-end]="
                                                    column.positionAtEnd
                                                " *ngIf="
                                                    item[column.key];
                                                    else noData
                                                ">{{
                                                phoneNumberService.phoneNumberFromServer(
                                                item[column.key]
                                                )
                                                }}</span>
                                            <ng-template #noData>
                                                <span></span>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Number  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.number">
                                            <ng-container *ngIf="item[column.key];else noData">
                                                <span [class.justify-content-end]="column.positionAtEnd"
                                                    *ngIf="column.isUnitAfterContent;else unitBefore">
                                                    {{column.isContainsComma? (item[column.key]|numberWithCommas): item[column.key]}} {{column.unit ? column.unit: ''}}
                                                </span>
                                                <ng-template #noData>
                                                    <span></span>
                                                </ng-template>
                                                <ng-template #unitBefore>
                                                    <span [class.justify-content-end]="column.positionAtEnd">
                                                        {{column.unit ? column.unit: ''}} {{column.isContainsComma? (item[column.key]|numberWithCommas): item[column.key]}}
                                                    </span>
                                                </ng-template>
                                            </ng-container>
                                            <ng-template #noData>
                                                <span></span>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Amount  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.amount">
                                            <span [class.justify-content-end]="
                                                    column.positionAtEnd
                                                " *ngIf="
                                                    item[column.key];
                                                    else noData
                                                ">${{column.isContainsComma? (item[column.key]|numberWithCommas): item[column.key]}}</span>
                                            <ng-template #noData>
                                                <span></span>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Actions Label  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.action">
                                            <div *ngIf="dataTableConfig?.allowActions" class="table-actions">
                                                <ng-container *ngIf="
                                                        dataTableConfig?.actionType ==
                                                        actionTypeEnum.Menu
                                                        && (dataTableConfig?.isShowActionOnlyInFirstRow ? i == 0 ? true : false : true)
                                                    ">
                                                    <div class="table-actions-list" [class.d-none]="
                                                            checkActionsNumber(item)
                                                        ">
                                                        <!-- <img src="/assets/images/{{ dataTableConfig?.actionIcon}}"
                                                            [matMenuTriggerFor]="menu" id="dropdown-actions"
                                                            aria-expanded="false" /> -->
                                                        <i class="icon-dots-vertical action-icon"
                                                            [matMenuTriggerFor]="menu" id="dropdown-actions"
                                                            aria-expanded="false"></i>
                                                        <mat-menu #menu="matMenu" xPosition="before">
                                                            <button mat-menu-item class="action-item body-short-2" [class.d-none]="
                                                                    action.hideAction
                                                                        ? action.hideAction(
                                                                              item
                                                                          )
                                                                        : false
                                                                " *ngFor="
                                                                    let action of dataTableConfig?.actionsList
                                                                " (click)="
                                                                    callAction(
                                                                        action,
                                                                        item
                                                                    )
                                                                ">
                                                                <!-- <img class="drop-down-item-icon" *ngIf="
                                                                        action.icon !==
                                                                        undefined
                                                                    " src="/assets/images/{{
                                                                        action.icon
                                                                    }}" /> -->
                                                                <i class="{{action.icon}} drop-down-item-icon"
                                                                    [class.danger-icon]="action.isDanger"></i>
                                                                <span class="action-label" [class.danger-action]="
                                                                        action.isDanger
                                                                    ">{{
                                                                    action.label
                                                                    | translate
                                                                    }}</span>
                                                            </button>
                                                        </mat-menu>
                                                    </div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="dataTableConfig?.actionType ==actionTypeEnum.Inline 
                                                        && (dataTableConfig?.isShowActionOnlyInFirstRow ? i == 0 ? true : false : true)">
                                                    <div class="inline-actions-icon">
                                                        <ng-container
                                                            *ngFor="let action of dataTableConfig?.actionsList">
                                                            <span class="{{action.class}}" placement="top"
                                                                ngbPopover="{{action.label |translate}}"
                                                                (click)="callAction(action,item)">
                                                                <!-- <img src="/assets/images/{{
                                                                        action.icon
                                                                    }}" /> -->
                                                                    <i class="{{action.icon}} drop-down-item-icon"
                                                                    [class.danger-icon]="action.isDanger"></i>
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Others Type Label  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchDefault>
                                            <ng-container *ngIf="
                                                    column.allowStatus &&
                                                        column.statusTemplate;
                                                    else noStatus
                                                ">
                                                <span [class.justify-content-end]="
                                                        column.positionAtEnd
                                                    ">
                                                    <ng-container *ngTemplateOutlet="
                                                            column.statusTemplate;
                                                            context: {
                                                                item: item,
                                                                column: column
                                                            }
                                                        ">
                                                    </ng-container>
                                                </span>
                                            </ng-container>
                                            <ng-template #noStatus>
                                                <span>{{ item[column.key] }}</span>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->
                                    </ng-container>
                                    <!-- <i class="icon-link-external-01 icon-s-m" *ngIf="column.columnLink && getIsOpenNewTab(column) && item[column.key]"
                                        style="float: inline-end;position: relative;top: -27px;right: 15px;color: #A8A8A8;"></i> -->
                                </a>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <!-- No Records -->
        <ng-container *ngIf="
                !isBusy && (tableData == undefined || tableData?.length == 0)
            ">
            <div class="no-records no-records-table">
                <div *ngIf="
                        dataTableConfig && dataTableConfig.noRecordTemplate;
                        else noTemplate
                    ">
                    <ng-container *ngTemplateOutlet="dataTableConfig.noRecordTemplate">
                    </ng-container>
                </div>
                <ng-template #noTemplate>
                    <h4 class="heading-4">
                        {{ "NO_RECORDS_FOUND" | translate }}
                    </h4>
                    <p class="body-short-2">
                        {{
                        "YOU_DONOT_HAVE_ANY_DATA_IN_THESE_TABLES"
                        | translate
                        }}
                    </p>
                </ng-template>
            </div>
        </ng-container>
        <!-- Loading -->
        <ng-container *ngIf="isBusy">
            <div class="no-records no-records-table">
                <h3 class="heading-3">{{ "LOADING" | translate }}...</h3>
                <p class="body-short-2">
                    {{ "YOUR_DATA_IS_LOADING_PLEASE_WAIT" | translate }}
                </p>
            </div>
        </ng-container>
        <!--  -->
    </div>
    <div class="row" [ngClass]="isBusy ? 'wrapper-disabled' : ''" *ngIf="showAs == 'card'">
        <ng-container *ngIf="!isBusy">
            <div class="col-12">
                <div class="card-container" (scroll)="onScroll($event)">
                    <div class="row">
                        <div *ngFor="let card of tableData" [ngClass]="dataTableConfig?.cardClass ?? ''">
                            <ng-container *ngIf="dataTableConfig && dataTableConfig.cardTemplate">
                                <ng-container *ngTemplateOutlet="
                                    dataTableConfig.cardTemplate;
                                    context: { card: card, cardComp: this }
                                ">
                                </ng-container>
                            </ng-container>
                        </div>
                        <!-- No Records -->
                        <div class="no-records col-12"
                            *ngIf="!isBusy && (tableData == undefined || tableData?.length == 0)">
                            <h4 class="heading-4">{{ "NO_RECORDS_FOUND" | translate }}</h4>
                            <p class="body-short-2">
                                {{ "YOU_DONOT_HAVE_ANY_DATA_IN_THESE_TABLES" | translate }}
                            </p>
                        </div>
                        <!-- Loading -->
                        <div class="no-records col-12" *ngIf="isBusy">
                            <h3 class="heading-3">{{ "LOADING" | translate }}...</h3>
                            <p class="body-short-2">
                                {{ "YOUR_DATA_IS_LOADING_PLEASE_WAIT" | translate }}
                            </p>
                        </div>
                        <!--  -->
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>


<!-- <div class="row" *ngIf="tableData?.length">
    <div class="col-md-5 col-12 mt-4 body-short-2 showing-section" *ngIf="dataTableConfig?.allowInfo">
        <span class="heading-6">{{ showRowFrom + "-" + showRowTo }}</span>
        {{ "OF" | translate }} {{ totalCount }} {{ "ENTRIES" | translate }}
    </div>
    <div class="col-md-7 col-12" *ngIf="dataTableConfig?.allowPaging && pages > 1">
        <pagination-controls (pageChange)="changePage($event)" previousLabel="" nextLabel="" [maxSize]="maxSize">
        </pagination-controls>
    </div>
</div> -->